import { template as template_6b9facfd3d8f45f9a70927d30223fc91 } from "@ember/template-compiler";
const FKLabel = template_6b9facfd3d8f45f9a70927d30223fc91(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
