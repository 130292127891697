import { template as template_c32a157d76514a6cbcbce34e31a65fdb } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const TagNotificationsTracking = template_c32a157d76514a6cbcbce34e31a65fdb(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="tagging.notifications"
    class="tag-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TagNotificationsTracking;
