import { template as template_db4ea52d4d5f4e74918a85a8f847f9b6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_db4ea52d4d5f4e74918a85a8f847f9b6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
