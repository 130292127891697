import { template as template_7f4dfed21f9848e49392396b28477e75 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7f4dfed21f9848e49392396b28477e75(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
