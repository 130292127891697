import { template as template_c7a9b68d9e154f9fa637be7d85184162 } from "@ember/template-compiler";
const FKText = template_c7a9b68d9e154f9fa637be7d85184162(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
